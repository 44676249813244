@import "../App.scss";

.eventinfocard{
    text-align:left;
    margin-top:3em;
    padding:3em;
    font-family:var(--text-font);
    border-radius:20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    #table-title{
        //color:var(--primary-pink);
        background: -webkit-linear-gradient(166deg, rgba(200,16,228,1) 0%, rgba(237,23,166,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight:var(--h1-weight);
        font-size:1.5em;
        font-weight:1.5em;
        margin-bottom: 1em;
    }

    #list{
        margin-top:.5em;
        align-items:end;
        margin-bottom:2em;
    }
    
    #table{
        margin-top:.5em;
        align-items:end;
        margin-bottom:2em;
        display:none;
    }

    #size-alert{
        margin-bottom:2em;
        color:#8c8c8c;
        display:flex;
        font-family:var(--text-font);
        font-size:1em;
        font-weight:700em;

    }

    #extract-alert{
        margin-bottom:2em;
        color:var(--primary-pink);
        display:flex;
        font-family:var(--text-font);
        font-size:1em;
        font-weight:700em;

    }

    #text{
        p{
            color:black;
            display:flex;
            font-family:var(--text-font);
            font-size:.5em;
            font-weight:700em;
        }
    }
}

.sub-title{
    color:#8c8c8c;
}
.row{ 
    margin-top:.5em;
    align-items:end;
    display:grid;
    font-size:.8em;
    font-weight:700;
    grid-template-columns:15% 20% 40% 25%;
}

.list{
    margin-top:.5em;
    align-items:end;
    display:grid;
    font-size:1em;
    font-weight:700;
    grid-template-columns:60% auto;
    .alignright{
        text-align: right;
    }
}

.list .list-title .tooltip{
    opacity:0.8;
    width: 15em;
    visibility:hidden;
    background-color: black;
    color: white;
    font-size: .8em;
    font-weight: 400;
    text-align: center;
    padding: 5px 5px;
    border-radius: 10px;
    position: absolute;
    //left:25%;
    //bottom:100%;
    z-index: 1;
}
.list .list-title:hover .tooltip{
    visibility: visible;
}



@media only screen and (min-width:762px){
    .row{
    margin-top:.5em;
    align-items:end;
    display:grid;
    font-size:.8em;
    font-weight:700;
    grid-template-columns:15% 20% 40% 25%;
    }
    #size-alert{
        display:none !important;
    }
    #table{
        margin-top:.5em;
        align-items:end;
        margin-bottom:2em;
        display: block !important;
    }
}

@media only screen and (min-width:1024px){
    #size-alert{
        display:none !important;
    }
    #table{
        margin-top:.5em;
        align-items:end;
        margin-bottom:2em;
        display: block !important;
    }
}
