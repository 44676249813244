@import "../App.scss";

.assetinfocard{
    text-align:left;
    margin-top:3em;
    padding:3em;
    font-family:var(--text-font);
    border-radius:20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    #table_title{
        ///color:var(--primary-pink);
        //color: rgb(200,16,228);
        background: -webkit-linear-gradient(166deg, rgba(200,16,228,1) 0%, rgba(237,23,166,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight:var(--h1-weight);
        font-size:1.5em;
        font-weight:1.5em;
    }
    
    #list{
        margin-top:2em;
        align-items:end;
        margin-bottom:2em;
    }

    .alignright{
        text-align: right;
    }

    .table34{
        display:none;
    }
    #sizealert{
        margin-top:1.5em;
        color:#8c8c8c;
        display:flex;
        font-family:var(--text-font);
        font-size:1em;

    }
}

.list{
    margin-top:.5em;
    align-items:end;
    display:grid;
    font-size:1em;
    font-weight:700;
    grid-template-columns:60% auto;
}


@media only screen and (min-width:762px){
    .assetinfocard{ 
        .table34{
            display:none !important;
        }
    }
    #sizealert{
        margin-top:1.5em;
        color:#8c8c8c;
        display:flex;
        font-family:var(--text-font);
        font-size:1em;

    }
}

@media only screen and (min-width:1024px){
    .assetinfocard{ 
        .table34{
            display:grid !important;
            align-items:end;
            margin-top:2em;
            grid-template-columns:10% 30% 20% 25% 15%;
            font-size:.8em;
            font-weight:700;
            .sub-title{
                color:#8c8c8c;
            }
            .col{
                align-self:end;
                margin-top:.5em;
            }
        }
    }
    #sizealert{
        display:none !important;
    }
}
