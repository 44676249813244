@import '../App.scss';
.blackcard{
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    align-items: space-between;
    font-family: var(--text-font);
    border-radius: 20px;
    background-color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0;
    padding: 1.5em;
    width: 30%;
    margin-right:auto;
    margin-left:auto;
    margin-top:.5em;
    .title{
        display:block;
        color:white;
        font-weight: var(--h2-weight);
        font-size: .8em;
        margin-bottom: 1em;
        position:relative;
    }
    .value{
        justify-self:flex-end;
        text-align:center;
        float:right;
        color: white;
        font-weight: var(--text-weight);
        font-size: 1.3em;
    }
    
}
.blackcard .title .tooltip{
    opacity:0.8;
    width: 15em;
    visibility:hidden;
    background-color: black;
    color: white;
    font-size: .8em;
    font-weight: 400;
    text-align: center;
    padding: 5px 5px;
    border-radius: 10px;
    position: absolute;
    left:25%;
    bottom:100%;
    z-index: 1;
}
.blackcard .title:hover .tooltip{
    visibility: visible;
}

@media only screen and (min-width:762px){
    .blackcard{
        align-items: space-between;
        width: 28% !important;
        margin: 0;
        margin-bottom:2em;
        text-align:center;
        padding:1em;
        .title{
        display:block;
        color:white;
        font-weight: var(--h2-weight);
        font-size: 1em !important;
        margin-bottom: 1em;
        }
        .value{
            text-align:center;
            font-size: 1.5em !important;
        }
    }
}