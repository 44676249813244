#navbar_container{
    display:flex;
    justify-content: right;
    align-items: center;
    padding: 1em;
    #logo_img{
        width: 1.5em;
        height: auto;
        margin-right: .5em;
    }
    #logo_text{
        font-family: var(--logo-font);
        font-weight: var(--logo-weight);
        font-size: 1.3em;
    }
}

@media only screen and (min-width:762px){
    #navbar_container{
        padding: 2em 3em !important;
    }
} 

@media only screen and (min-width:1024px){
    #navbar_container{
        padding: 3em 10em !important;
        #logo_img{
            width: 1.6em !important;
            height: auto;
            margin-right: 1.3em;
        }
        #logo_text{
            font-family: var(--logo-font);
            font-weight: var(--logo-weight);
            font-size: 1.5em !important;
        }
    }
    
} 


@media only screen and (min-width:1670px){
    #navbar_container{
        padding: 3em 15em !important;
    }
    
} 
