#data_container{
    padding: 1em;
    #black_card_container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 0em;
        width:100%;
    }
    #toggle_container{
        display:flex;
        flex-flow: row;
        float: right;
        margin-bottom: 2em;
        #right_text{
            margin-left: 10px;
            align-self: center;
            font-family: var(--text-font);
            font-weight: 700;
        }
    }
}

@media only screen and (min-width:762px){
    #data_container{
        padding: 2em 3em;
        #black_card_container{
            display:flex !important;
            flex-wrap: wrap !important;
            flex-direction: row !important;
            width: 100%;
            place-content:start space-between !important;
            //text-align:center;
        }
    }
}

@media only screen and (min-width:1024px){
    #data_container{
        margin-top: 3em;
        padding:3em 10em;
    }
}

@media only screen and (min-width:1670px){
    #data_container{
        margin-top: 3em;
        padding:3em 15em;
    }
}